import React, { useEffect, useReducer } from "react";
import { CustomModal } from "../../../../components/CustomModal/CustomModal";
import {
  CustomInput,
  CustomInputPassword,
} from "../../../../components/CustomInput/CustomInput";
import { CustomButton } from "../../../../components/CustomButton/CustomButton";
import {
  onCustomInputChange,
  onCustomFocusOut,
  formsReducer,
  validateCustomForm,
  fillFormAction,
  updateFormAction,
} from "../../../../helpers/formUtils";
import { useDispatch } from "react-redux";
import { hideLoaderAction, showLoaderAction } from "../../../../redux/actions";
import {
  addCollaborator,
  updateCollaborator,
} from "../../../../crud/collaborators";
import { reduceFormData } from "../../../../helpers/reduceFormData";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./modal.scss";

const initialState = {
  fname: {
    isRequired: true,
    value: "",
    touched: false,
    hasError: true,
    error: "",
  },
  lname: {
    isRequired: true,
    value: "",
    touched: false,
    hasError: true,
    error: "",
  },
  company: {
    isRequired: false,
    value: "",
    touched: false,
    hasError: true,
    error: "",
  },
  jobTitle: {
    isRequired: false,
    value: "",
    touched: false,
    hasError: true,
    error: "",
  },
  email: {
    isRequired: true,
    value: "",
    touched: false,
    hasError: true,
    error: "",
  },
  street: {
    isRequired: false,
    value: "",
    touched: false,
    hasError: true,
    error: "",
  },
  city: {
    isRequired: false,
    value: "",
    touched: false,
    hasError: true,
    error: "",
  },
  zip: {
    isRequired: false,
    value: "",
    touched: false,
    hasError: true,
    error: "",
  },
  phoneNumber: {
    isRequired: false,
    value: "",
    touched: false,
    hasError: true,
    error: "",
  },
  website: {
    isRequired: false,
    value: "",
    touched: false,
    hasError: true,
    error: "",
  },
  password: {
    isRequired: true,
    value: "",
    touched: false,
    hasError: true,
    error: "",
  },
  confirmPassword: {
    isRequired: true,
    value: "",
    touched: false,
    hasError: true,
    error: "",
  },
  isFormValid: false,
};

export const ModalAddEditCollaborator = ({ closeModal, current, loadData }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useReducer(formsReducer, initialState);

  useEffect(() => {
    if (current) {
      setFormData(
        fillFormAction({
          fname: {
            isRequired: true,
            value: current.fname,
            touched: false,
            hasError: true,
            error: "",
          },
          lname: {
            isRequired: true,
            value: current.lname,
            touched: false,
            hasError: true,
            error: "",
          },
          company: {
            isRequired: false,
            value: current.company,
            touched: false,
            hasError: true,
            error: "",
          },
          jobTitle: {
            isRequired: false,
            value: current.jobTitle,
            touched: false,
            hasError: true,
            error: "",
          },
          email: {
            isRequired: true,
            value: current.email,
            touched: false,
            hasError: true,
            error: "",
          },
          street: {
            isRequired: false,
            value: current.street,
            touched: false,
            hasError: true,
            error: "",
          },
          city: {
            isRequired: false,
            value: current.city,
            touched: false,
            hasError: true,
            error: "",
          },
          zip: {
            isRequired: false,
            value: current.zip,
            touched: false,
            hasError: true,
            error: "",
          },
          phoneNumber: {
            isRequired: false,
            value: current.phonePrefix + current.phoneNumber,
            touched: false,
            hasError: true,
            error: "",
          },
          website: {
            isRequired: false,
            value: current.website,
            touched: false,
            hasError: true,
            error: "",
          },
          password: {
            isRequired: false,
            value: current.password,
            touched: false,
            hasError: true,
            error: "",
          },
          confirmPassword: {
            isRequired: false,
            value: current.confirmPassword,
            touched: false,
            hasError: true,
            error: "",
          },
          isFormValid: false,
        })
      );
    }
  }, [current]);

  const handleAddCollaborator = () => {
    // Validate and set "N.A." for empty fields, except for phone number
    const updatedFormData = { ...formData };

    Object.keys(updatedFormData).forEach((key) => {
      if (
        key !== "phoneNumber" &&
        key !== "isFormValid" &&
        key !== "zip" &&
        key !== "website" &&
        key !== "password" &&
        key !== "confirmPassword" &&
        (!updatedFormData[key].value ||
          updatedFormData[key].value.trim() === "")
      ) {
        updatedFormData[key].value = " ";
      } else {
        if (key === "zip") {
          if (updatedFormData[key].value === "") {
            updatedFormData[key].value = " ";
          }
        }
        if (key === "website") {
          if (updatedFormData[key].value === "") {
            updatedFormData[key].value = "https://www.urlExample.com/";
          }
        }
      }
    });

    if (validateCustomForm(updatedFormData, setFormData)) {
      dispatch(showLoaderAction());
      let phoneData = "";
      if (
        typeof updatedFormData.phoneNumber.value === "string" &&
        updatedFormData.phoneNumber.value.trim() !== ""
      ) {
        phoneData = parsePhoneNumber(updatedFormData.phoneNumber.value);
      } else {
        phoneData = {
          country: "US", // Replace with your default country
          nationalNumber: "5550000000",
          countryCallingCode: "1",
        };
      }
      const data = reduceFormData(updatedFormData);
      if (!data.website) {
        delete data.website;
      }
      data.country = phoneData.country;
      data.phone = {
        phoneNumber: phoneData.nationalNumber,
        phonePrefix: "+" + phoneData.countryCallingCode,
      };
      if (!data.country) {
        data.country = "US";
      }
      delete data.phoneNumber;

      // Remove the confirmPassword field
      delete data.confirmPassword;

      addCollaborator(data)
        .then(() => {
          dispatch(hideLoaderAction());
          closeModal();
          loadData();
        })
        .catch((err) => {
          // Log the error response
          console.error("Error response:", err.response);

          if (err?.response?.data?.message === "email already in use") {
            setFormData(
              updateFormAction({
                name: "email",
                value: updatedFormData.email.value,
                hasError: true,
                error: "email already in use",
                touched: true,
                isFormValid: false,
              })
            );
          } else if (
            err?.response?.data?.message[0] === "website must be an URL address"
          ) {
            setFormData(
              updateFormAction({
                name: "website",
                value: updatedFormData.website.value,
                hasError: true,
                error: "website must be an URL address",
                touched: true,
                isFormValid: false,
              })
            );
          } else if (
            err?.response?.data?.message === "User with email already exists"
          ) {
            setFormData(
              updateFormAction({
                name: "email",
                value: updatedFormData.website.value,
                hasError: true,
                error:
                  "email already associated with another user, please write another email",
                touched: true,
                isFormValid: false,
              })
            );
          }
        });
    }

    Object.keys(updatedFormData).forEach((key) => {
      if (key !== "isFormValid") {
        updatedFormData[key].value = "";
      }
    });
  };

  const onUpdateCollaborator = () => {
    // Validate and set "N.A." for empty fields, except for phone number
    const updatedFormData = { ...formData };

    Object.keys(updatedFormData).forEach((key) => {
      if (
        key !== "phoneNumber" &&
        key !== "isFormValid" &&
        key !== "zip" &&
        key !== "website" &&
        key !== "password" &&
        key !== "confirmPassword" &&
        (!updatedFormData[key].value ||
          updatedFormData[key].value.trim() === "")
      ) {
        updatedFormData[key].value = " ";
      } else {
        if (key === "zip") {
          if (updatedFormData[key].value === "") {
            updatedFormData[key].value = " ";
          }
        }
        if (key === "website") {
          if (updatedFormData[key].value === "") {
            updatedFormData[key].value = "https://www.urlExample.com/";
          }
        }
      }
    });

    if (validateCustomForm(updatedFormData, setFormData)) {
      dispatch(showLoaderAction());
      let phoneData = "";
      if (
        typeof updatedFormData.phoneNumber.value === "string" &&
        updatedFormData.phoneNumber.value.trim() !== ""
      ) {
        phoneData = parsePhoneNumber(updatedFormData.phoneNumber.value);
      } else {
        phoneData = {
          country: "US", // Replace with your default country
          nationalNumber: "5550000000",
          countryCallingCode: "1",
        };
      }
      const data = reduceFormData(updatedFormData);
      if (!data.website) {
        delete data.website;
      }
      data.country = phoneData.country;
      data.phone = {
        phoneNumber: phoneData.nationalNumber,
        phonePrefix: "+" + phoneData.countryCallingCode,
      };
      if (!data.country) {
        data.country = "US";
      }
      delete data.phoneNumber;

      // Remove the confirmPassword field
      delete data.confirmPassword;

      updateCollaborator(current.id, data)
        .then(() => {
          dispatch(hideLoaderAction());
          closeModal();
          loadData();
        })
        .catch((err) => {
          if (err?.response?.data?.message === "email already in use") {
            setFormData(
              updateFormAction({
                name: "email",
                value: formData.email.value,
                hasError: true,
                error: "email already in use",
                touched: true,
                isFormValid: false,
              })
            );
          } else if (
            err?.response?.data?.message[0] === "website must be an URL address"
          ) {
            setFormData(
              updateFormAction({
                name: "website",
                value: formData.website.value,
                hasError: true,
                error: "website must be an URL address",
                touched: true,
                isFormValid: false,
              })
            );
          }
        });
    }
  };

  const copyPwdToClipboard = () => {
    navigator.clipboard
      .writeText(formData.password.value)
      .then(() => {
        alert("Password copied to clipboard"); // Show alert to user
      })
      .catch((err) => {
        console.error("Failed to copy password: ", err);
      });
  };

  const generatePassword = () => {
    const length = Math.floor(Math.random() * (32 - 12 + 1)) + 12;
    const lowercase = "abcdefghijklmnopqrstuvwxyz";
    const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const numbers = "0123456789";
    const specialChars = "!@#$%^&*()_+[]{}|;:,.<>?";
    const allChars = lowercase + uppercase + numbers + specialChars;

    let password = "";
    password += lowercase[Math.floor(Math.random() * lowercase.length)];
    password += uppercase[Math.floor(Math.random() * uppercase.length)];
    password += numbers[Math.floor(Math.random() * numbers.length)];
    password += specialChars[Math.floor(Math.random() * specialChars.length)];

    for (let i = password.length; i < length; i++) {
      password += allChars[Math.floor(Math.random() * allChars.length)];
    }

    return password
      .split("")
      .sort(() => 0.5 - Math.random())
      .join("");
  };

  const handleGeneratePassword = () => {
    const newPassword = generatePassword();
    setFormData(
      updateFormAction({
        name: "password",
        value: newPassword,
        hasError: false,
        error: "",
        touched: true,
        isFormValid: formData.isFormValid,
      })
    );
    setFormData(
      updateFormAction({
        name: "confirmPassword",
        value: newPassword,
        hasError: false,
        error: "",
        touched: true,
        isFormValid: formData.isFormValid,
      })
    );
  };

  return (
    <div className="add-edit-collaborator-modal">
      <CustomModal
        title={`${current ? "Edit" : "Add"} Collaborator`}
        close={closeModal}
        submit={current ? onUpdateCollaborator : handleAddCollaborator}
      >
        <div className="add-edit-collaborator-modal__input-container">
          <div className="add-edit-collaborator-modal__input-name">
            First Name
          </div>
          <CustomInput
            name="fname"
            formData={formData.fname}
            onChange={(e) =>
              onCustomInputChange(
                "fname",
                e.target.value,
                setFormData,
                formData
              )
            }
            onBlur={(e) => {
              onCustomFocusOut("fname", e.target.value, setFormData, formData);
            }}
            variantError="topright"
          />
        </div>
        <div className="add-edit-collaborator-modal__input-container">
          <div className="add-edit-collaborator-modal__input-name">
            Last Name
          </div>
          <CustomInput
            name="lname"
            formData={formData.lname}
            onChange={(e) =>
              onCustomInputChange(
                "lname",
                e.target.value,
                setFormData,
                formData
              )
            }
            onBlur={(e) => {
              onCustomFocusOut("lname", e.target.value, setFormData, formData);
            }}
            variantError="topright"
          />
        </div>
        <div className="add-edit-collaborator-modal__input-container">
          <div className="add-edit-collaborator-modal__input-name">Company</div>
          <CustomInput
            name="company"
            formData={formData.company}
            onChange={(e) =>
              onCustomInputChange(
                "company",
                e.target.value,
                setFormData,
                formData
              )
            }
            onBlur={(e) => {
              onCustomFocusOut(
                "company",
                e.target.value,
                setFormData,
                formData
              );
            }}
            variantError="topright"
          />
        </div>
        <div className="add-edit-collaborator-modal__input-container">
          <div className="add-edit-collaborator-modal__input-name">
            Job title
          </div>
          <CustomInput
            name="jobTitle"
            formData={formData.jobTitle}
            onChange={(e) =>
              onCustomInputChange(
                "jobTitle",
                e.target.value,
                setFormData,
                formData
              )
            }
            onBlur={(e) => {
              onCustomFocusOut(
                "jobTitle",
                e.target.value,
                setFormData,
                formData
              );
            }}
            variantError="topright"
          />
        </div>
        <div className="add-edit-collaborator-modal__input-container">
          <div className="add-edit-collaborator-modal__input-name">Email</div>
          <CustomInput
            name="email"
            formData={formData.email}
            onChange={(e) =>
              onCustomInputChange(
                "email",
                e.target.value,
                setFormData,
                formData
              )
            }
            onBlur={(e) => {
              onCustomFocusOut("email", e.target.value, setFormData, formData);
            }}
            variantError="topright"
            maxLength={100}
          />
        </div>
        <div className="add-edit-collaborator-modal__input-container">
          <div className="add-edit-collaborator-modal__input-name">Street</div>
          <CustomInput
            name="street"
            formData={formData.street}
            onChange={(e) =>
              onCustomInputChange(
                "street",
                e.target.value,
                setFormData,
                formData
              )
            }
            onBlur={(e) => {
              onCustomFocusOut("street", e.target.value, setFormData, formData);
            }}
            variantError="topright"
          />
        </div>
        <div className="flex-sb">
          <div className="add-edit-collaborator-modal__input-container add-edit-collaborator-modal__city">
            <div className="add-edit-collaborator-modal__input-name">City</div>
            <CustomInput
              name="city"
              formData={formData.city}
              onChange={(e) =>
                onCustomInputChange(
                  "city",
                  e.target.value,
                  setFormData,
                  formData
                )
              }
              onBlur={(e) => {
                onCustomFocusOut("city", e.target.value, setFormData, formData);
              }}
              variantError="topright"
            />
          </div>
          <div className="add-edit-collaborator-modal__input-container add-edit-collaborator-modal__zip">
            <div className="add-edit-collaborator-modal__input-name">Zip</div>
            <CustomInput
              name="zip"
              formData={formData.zip}
              onChange={(e) =>
                onCustomInputChange(
                  "zip",
                  e.target.value,
                  setFormData,
                  formData
                )
              }
              onBlur={(e) => {
                onCustomFocusOut("zip", e.target.value, setFormData, formData);
              }}
              variantError="topright"
              maxLength={25}
            />
          </div>
        </div>
        <div className="add-edit-collaborator-modal__input-container">
          <div className="add-edit-collaborator-modal__input-name">
            Country / Phone
          </div>
          {formData.phoneNumber.touched && formData.phoneNumber.hasError && (
            <div className="phone-input-error-text">
              {formData.phoneNumber.error || "This field if required"}
            </div>
          )}

          <PhoneInput
            value={formData.phoneNumber.value}
            onChange={(value) =>
              onCustomInputChange("phoneNumber", value, setFormData, formData)
            }
            onBlur={() =>
              onCustomFocusOut(
                "phoneNumber",
                formData.phoneNumber.value,
                setFormData,
                formData
              )
            }
            className={
              formData.phoneNumber.touched && formData.phoneNumber.hasError
                ? "phone-input-error"
                : ""
            }
            maxLength={18}
          />
        </div>
        <div className="add-edit-collaborator-modal__input-container">
          <div className="add-edit-collaborator-modal__input-name">Website</div>
          <CustomInput
            formData={formData.website}
            onChange={(e) =>
              onCustomInputChange(
                "website",
                e.target.value,
                setFormData,
                formData
              )
            }
            onBlur={(e) => {
              onCustomFocusOut(
                "website",
                e.target.value,
                setFormData,
                formData
              );
            }}
            variantError="topright"
          />
        </div>
        <div className="flex-sb">
          <div className="add-edit-collaborator-modal__input-container">
            <div className="add-edit-collaborator-modal__input-name">
              Password
            </div>
            <CustomInputPassword
              name="password"
              type="password"
              formData={formData.password}
              onChange={(e) =>
                onCustomInputChange(
                  "password",
                  e.target.value,
                  setFormData,
                  formData
                )
              }
              onBlur={(e) => {
                onCustomFocusOut(
                  "password",
                  e.target.value,
                  setFormData,
                  formData
                );
              }}
              variantError="bottomleft"
              isConfirmPassword={false}
              buttonParameters={(e) => {
                e.preventDefault();
                handleGeneratePassword(setFormData, formData);
              }}
            />
          </div>
        </div>
        <div className="add-edit-collaborator-modal__input-container">
          <div className="add-edit-collaborator-modal__input-name">
            Confirm Password
          </div>
          <CustomInputPassword
            name="confirmPassword"
            type="password"
            formData={formData.confirmPassword}
            onChange={(e) =>
              onCustomInputChange(
                "confirmPassword",
                e.target.value,
                setFormData,
                formData
              )
            }
            onBlur={(e) => {
              onCustomFocusOut(
                "confirmPassword",
                e.target.value,
                setFormData,
                formData
              );
            }}
            variantError="bottomleft"
            isConfirmPassword={true}
            buttonParameters={(e) => {
              e.preventDefault();
              copyPwdToClipboard(formData.password.value);
            }}
          />
        </div>
      </CustomModal>
    </div>
  );
};
