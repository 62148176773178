import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";

const UPDATE_FORM = "UPDATE_FORM";
const FILL_FORM = "FILL_FORM";
const TO_NULL = "TO_NULL";

export const updateFormAction = (data) => {
  return {
    type: UPDATE_FORM,
    data,
  };
};

export const fillFormAction = (payload) => {
  return {
    type: FILL_FORM,
    payload,
  };
};

export const toNull = (payload) => {
  return {
    type: TO_NULL,
    payload,
  };
};

export const validateInput = (name, value, validate) => {
  const emailReg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const websiteReg = /^(http|https):/;
  const passwordReg =
    /(?=.*[-!$%#^&*()_+|~=`{}\[\]:";'<>?,.\/])(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
  const matterport =
    /(?:https?:\/\/)?(?:(?:(?:my\.?)?matterport\.com\/show\/\?m=([0-9a-zA-Z]+)+))/;
  function isValidHttpUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }

  let hasError = false;
  let error = "";

  if (validate === false) return { hasError, error };

  switch (name) {
    case "presentedBy":
      if (value.trim() === "") {
        hasError = true;
        error = <p>This field is required</p>;
      }
      break;
    case "name":
      if (value.trim() === "") {
        hasError = true;
        error = <p>This field is required</p>;
      }
      break;
    case "text":
      if (value?.trim() === "") {
        hasError = true;
        error = <p>This field is required</p>;
      } else if (!value) {
        hasError = true;
        error = <p>This field is required</p>;
      }
      break;
    case "username":
      if (value.trim() === "") {
        hasError = true;
        error = <p>This field is required</p>;
      }
      break;
    case "fullname":
      if (value.trim() === "") {
        hasError = true;
        error = <p>This field is required</p>;
      }
      break;
    case "fname":
      if (value.trim() === "") {
        hasError = true;
        error = <p>This field is required</p>;
      }
      break;
    case "lname":
      if (value.trim() === "") {
        hasError = true;
        error = <p>This field is required</p>;
      }
      break;
    case "company":
      if (value.trim() === "") {
        hasError = true;
        error = <p>This field is required</p>;
      }
      break;
    case "jobTitle":
      if (value.trim() === "") {
        hasError = true;
        error = <p>This field is required</p>;
      }
      break;
    case "email":
      if (value.trim() === "") {
        hasError = true;
        error = <p>This field is required</p>;
      } else if (!emailReg.test(String(value).toLowerCase())) {
        hasError = true;
        error = (
          <>
            <p>That's not a valid email.</p>
            <p>Try a different email address</p>
          </>
        );
      }
      break;
    case "contactEmail":
      if (value && !emailReg.test(String(value).toLowerCase())) {
        hasError = true;
        error = (
          <>
            <p>That's not a valid email.</p>
            <p>Try a different email address</p>
          </>
        );
      }
      break;
    case "street":
      if (value.trim() === "") {
        hasError = true;
        error = <p>This field is required</p>;
      }
      break;
    case "city":
      if (value.trim() === "") {
        hasError = true;
        error = <p>This field is required</p>;
      }
      break;
    case "zip":
      if (value.trim() === "") {
        hasError = true;
        error = <p>This field is required</p>;
      }
      break;
    case "website":
      if (value.trim() === "") {
        break;
      } else if (!websiteReg.test(String(value).toLowerCase())) {
        hasError = true;
        error = <p>Website must start from http or https</p>;
      } else if (!isValidHttpUrl(value)) {
        hasError = true;
        error = <p>Website must be an URL address</p>;
      }
      break;
    case "externalUrl":
      if (value.trim() === "") {
        break;
      } else if (!websiteReg.test(String(value).toLowerCase())) {
        hasError = true;
        error = <p>Website must start from http or https</p>;
      } else if (!isValidHttpUrl(value)) {
        hasError = true;
        error = <p>Website must be an URL address</p>;
      }
      break;
    case "url":
      if (value?.trim() === "") {
        hasError = true;
        error = <p>This field is required</p>;
      } else if (!value) {
        hasError = true;
        error = <p>This field is required</p>;
      } else if (!websiteReg.test(String(value).toLowerCase())) {
        hasError = true;
        error = <p>Website must start from http or https</p>;
      } else if (!isValidHttpUrl(value)) {
        hasError = true;
        error = <p>Website must be an URL address</p>;
      }
      break;
    case "descriptions":
      if (!value || value?.trim() === "") {
        hasError = true;
        error = <p>This field is required</p>;
      } else if (value === "<p></p>\n") {
        hasError = true;
        error = <p>This field is required</p>;
      }
      break;
    case "matterportUrl":
      if (value.trim() === "") {
        hasError = true;
        error = <p>This field is required</p>;
      } else if (!websiteReg.test(String(value).toLowerCase())) {
        hasError = true;
        error = <p>Website must start from http or https</p>;
      } else if (!isValidHttpUrl(value)) {
        hasError = true;
        error = <p>Website must be an URL address</p>;
      } else if (!matterport.test(String(value).toLowerCase())) {
        hasError = true;
        error = <p>The URL must match a Matterport URL</p>;
      }
      break;
    case "password":
      if (value.trim() === "") {
        hasError = true;
        error = (
          <div>
            <p>This field is required</p>
          </div>
        );
      } else if (!passwordReg.test(String(value))) {
        hasError = true;
        error = (
          <div>
            <p>
              Password must contain 6 - 32 characters. <br />
              Should include at least 1 lowercase, 1 uppercase, 1 special
              symbol, 1 number
            </p>
          </div>
        );
      }
      break;
    case "changePassword":
      if (value.trim() === "") {
      } else if (!passwordReg.test(String(value))) {
        hasError = true;
        error = (
          <>
            <p>That's not a valid password</p>
          </>
        );
      }
      break;
    case "phoneNumber":
      if (!value) {
        hasError = true;
        error = <p>This field is required</p>;
      } else if (!isPossiblePhoneNumber(value) || !isValidPhoneNumber(value)) {
        hasError = true;
        error = <p>Incorrect phone number</p>;
      }
      break;
    case "contactPhone":
      if (
        value &&
        (!isPossiblePhoneNumber(value) || !isValidPhoneNumber(value))
      ) {
        hasError = true;
        error = <p>Incorrect phone number</p>;
      }
      break;
    case "confirm":
      if (value.trim() === "") {
        hasError = true;
        error = <p>This field is required</p>;
      }
      break;
    case "title":
      if (value.trim() === "") {
        hasError = true;
        error = <p>This field is required</p>;
      }
      break;
    case "tileTitle":
      if (value && value?.length > 30) {
        hasError = true;
        error = <p>Max length 30 character</p>;
      }
      break;
    case "address":
      if (value.trim() === "") {
        hasError = true;
        error = <p>This field is required</p>;
      }
      break;
    default:
      break;
  }

  return { hasError, error };
};

export const validateCustomInput = (name, value, validate) => {
  const emailReg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const websiteReg = /^(http|https):/;
  const passwordReg =
    /(?=.*[-!$%#^&*()_+|~=`{}\[\]:";'<>?,.\/])(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
  const matterport =
    /(?:https?:\/\/)?(?:(?:(?:my\.?)?matterport\.com\/show\/\?m=([0-9a-zA-Z]+)+))/;
  function isValidHttpUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }

  let hasError = false;
  let error = "";

  if (validate === false) return { hasError, error };

  switch (name) {
    case "presentedBy":
      break;
    case "name":
      break;
    case "text":
      break;
    case "username":
      break;
    case "fullname":
      break;
    case "fname":
      if (value.trim() === "") {
        hasError = true;
        error = <p>This field is required</p>;
      }
      break;
    case "lname":
      if (value.trim() === "") {
        hasError = true;
        error = <p>This field is required</p>;
      }
      break;
    case "company":
      break;
    case "jobTitle":
      break;
    case "email":
      if (!emailReg.test(String(value).toLowerCase())) {
        hasError = true;
        error = (
          <>
            <p>That's not a valid email.</p>
            <p>Try a different email address</p>
          </>
        );
      }
      break;
    case "contactEmail":
      if (value && !emailReg.test(String(value).toLowerCase())) {
        hasError = true;
        error = (
          <>
            <p>That's not a valid email.</p>
            <p>Try a different email address</p>
          </>
        );
      }
      break;
    case "street":
      break;
    case "city":
      break;
    case "zip":
      break;
    case "website":
      if (value.trim() !== "") {
        if (!websiteReg.test(String(value).toLowerCase())) {
          hasError = true;
          error = <p>Website must start from http or https</p>;
        } else if (!isValidHttpUrl(value)) {
          hasError = true;
          error = <p>Website must be an URL address</p>;
        }
      }
      break;
    case "externalUrl":
      if (value.trim() !== "") {
        if (!websiteReg.test(String(value).toLowerCase())) {
          hasError = true;
          error = <p>Website must start from http or https</p>;
        } else if (!isValidHttpUrl(value)) {
          hasError = true;
          error = <p>Website must be an URL address</p>;
        }
      }
      break;
    case "url":
      if (value?.trim() !== "") {
        if (!websiteReg.test(String(value).toLowerCase())) {
          hasError = true;
          error = <p>Website must start from http or https</p>;
        } else if (!isValidHttpUrl(value)) {
          hasError = true;
          error = <p>Website must be an URL address</p>;
        }
      }
      break;
    case "descriptions":
      if (!value || value?.trim() === "") {
        hasError = true;
        error = <p>This field is required</p>;
      } else if (value === "<p></p>\n") {
        hasError = true;
        error = <p>This field is required</p>;
      }
      break;
    case "matterportUrl":
      if (value.trim() !== "") {
        if (!websiteReg.test(String(value).toLowerCase())) {
          hasError = true;
          error = <p>Website must start from http or https</p>;
        } else if (!isValidHttpUrl(value)) {
          hasError = true;
          error = <p>Website must be an URL address</p>;
        } else if (!matterport.test(String(value).toLowerCase())) {
          hasError = true;
          error = <p>The URL must match a Matterport URL</p>;
        }
      }
      break;
    case "phoneNumber":
      if (value) {
        if (!isPossiblePhoneNumber(value) || !isValidPhoneNumber(value)) {
          hasError = true;
          error = <p>Incorrect phone number</p>;
        }
      }
      break;
    case "contactPhone":
      if (value) {
        if (!isPossiblePhoneNumber(value) || !isValidPhoneNumber(value)) {
          hasError = true;
          error = <p>Incorrect phone number</p>;
        }
      }
      break;
    case "confirm":
      break;
    case "title":
      break;
    case "tileTitle":
      if (value) {
        if (value?.length > 30) {
          hasError = true;
          error = <p>Max length 30 character</p>;
        }
      }
      break;
    case "address":
      break;
    default:
      break;
  }

  return { hasError, error };
};

const validateEmptyInput = (value, validate) => {
  let hasError = false;
  let error = "";

  if (validate === false) return { hasError, error };

  if (!value && value.trim() === "") {
    hasError = true;
    error = (
      <div>
        <p>This field is required</p>
      </div>
    );
  }

  return { hasError, error };
};
const validatePassword = (name, value, formState) => {
  const passwordReg =
    /(?=.*[-!$%#^&*()_+|~=`{}\[\]:";'<>?,.\/])(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;

  let hasError = false;
  let error = "";
  switch (name) {
    case "password":
      if (value.trim() !== "" && !passwordReg.test(String(value))) {
        hasError = true;
        error = (
          <div>
            <p>
              Password must contain 6 - 32 characters. <br />
              Should include at least 1 lowercase, 1 uppercase, 1 special
              symbol, 1 number
            </p>
          </div>
        );
      }
      break;
    case "confirmPassword":
      if (value !== formState.password.value) {
        hasError = true;
        error = (
          <div>
            <p>Passwords do not match</p>
          </div>
        );
      }
      break;
    case "changePassword":
      if (value.trim() !== "" && !passwordReg.test(String(value))) {
        hasError = true;
        error = (
          <>
            <p>That's not a valid password</p>
          </>
        );
      }
      break;
  }
  return { hasError, error };
};

const validatePhoneNumber = (name, value, isRequired, dispatch) => {
  let hasError = false;
  let error = "";
  if (value) {
    if (isRequired) {
      if (!isPossiblePhoneNumber(value) || !isValidPhoneNumber(value)) {
        hasError = true;
        error = <p>Incorrect phone number</p>;
      }
    } else {
      if (value.trim() === "") {
        dispatch(
          updateFormAction({
            name,
            value: "+15550000000",
            hasError,
            error,
            touched: false,
            isFormValid,
          })
        );
      }
    }
  }
  return { hasError, error };
};

export const onCustomInputChange = (
  name,
  value,
  dispatch,
  formState,
  validate = true
) => {
  let hasError;
  let error;

  if (formState[name].isRequired) {
    ({ hasError, error } = validateEmptyInput(value, validate));
  }

  if (!hasError) {
    if (name.includes("password")) {
      ({ hasError, error } = validatePassword(name, value, formState));
    } else {
      ({ hasError, error } = validateCustomInput(
        name,
        value,
        validate,
        formState
      ));
    }
  }

  let isFormValid = true;

  for (const key in formState) {
    const item = formState[key];
    // Check if the current field has error
    if (key === name && hasError) {
      isFormValid = false;
      break;
    } else if (key !== name && item.hasError) {
      // Check if any other field has error
      isFormValid = false;
      break;
    }
  }

  dispatch(
    updateFormAction({
      name,
      value,
      hasError,
      error,
      touched: false,
      isFormValid,
    })
  );
};

export const onCustomFocusOut = (
  name,
  value,
  dispatch,
  formState,
  validate = true
) => {
  let hasError;
  let error;

  if (formState[name].isRequired) {
    ({ hasError, error } = validateEmptyInput(value, validate));
  }

  if (!hasError) {
    if (name.includes("password")) {
      ({ hasError, error } = validatePassword(name, value, formState));
    } else {
      ({ hasError, error } = validateCustomInput(
        name,
        value,
        validate,
        formState
      ));
    }
  }

  let isFormValid = true;
  for (const key in formState) {
    const item = formState[key];
    if (key === name && hasError) {
      isFormValid = false;
      break;
    } else if (key !== name && item.hasError) {
      isFormValid = false;
      break;
    }
  }

  dispatch(
    updateFormAction({
      name,
      value,
      hasError,
      error,
      touched: true,
      isFormValid,
    })
  );
};

export const validateCustomForm = (
  formData,
  dispatch,
  isUpdateForm = false
) => {
  let formDataArray = Object.keys(formData);
  let isFormValid = true;

  formDataArray.forEach((elem) => {
    if (elem !== "isFormValid") {
      let hasError = false;
      let error = "";
      if (formData[elem].isRequired) {
        if (elem.includes("password") || elem.includes("Password")) {
          ({ hasError, error } = validatePassword(
            elem,
            formData[elem].value,
            formData
          ));
        } else {
          ({ hasError, error } = validateCustomInput(
            elem,
            formData[elem].value
          ));
        }

        // if password or confirmPassword are not set, then isFormValid is false
        if (elem.includes("password") || elem.includes("Password")) {
          if (formData[elem].value.trim() === "") {
            isFormValid = false;
            hasError = true;
            error = (
              <div>
                <p>Password cannot be empty</p>
              </div>
            );
          }
        }

        if (
          elem.includes("confirmPassword") ||
          elem.includes("ConfirmPassword")
        ) {
          if (formData[elem].value.trim() === "") {
            isFormValid = false;
            hasError = true;
            error = (
              <div>
                <p>Confirm Password cannot be empty</p>
              </div>
            );
          }
        }

        if (hasError === true) {
          isFormValid = false;
          console.error(`Error en ${elem}: ${error}`);
        }

        dispatch({
          type: UPDATE_FORM,
          data: {
            ...formData[elem],
            name: elem,
            hasError,
            error,
            touched: true,
            isFormValid,
          },
        });
      } else {
        if (isUpdateForm) {
          if (elem.includes("password") || elem.includes("Password")) {
            if (formData[elem].value.trim() !== "") {
              ({ hasError, error } = validatePassword(
                elem,
                formData[elem].value,
                formData
              ));
              if (hasError === true) {
                console.error(`Error en ${elem}: ${error}`);
              }
            }
          }
        }

        if (elem.includes("phoneNumber")) {
          ({ hasError, error } = validatePhoneNumber(
            elem,
            formData[elem].value,
            formData[elem].isRequired,
            dispatch
          ));
          if (hasError === true) {
            console.error(`Error en ${elem}: ${error}`);
          }
        }
      }
    }
  });

  return isFormValid;
};

export const validateCustomFrontendUserForm = (
  formData,
  dispatch,
  isUpdateForm = false
) => {
  let formDataArray = Object.keys(formData);
  let isFormValid = true;

  formDataArray.forEach((elem) => {
    if (elem !== "isFormValid") {
      let hasError = false;
      let error = "";
      if (formData[elem].isRequired) {
        if (elem.includes("password") || elem.includes("Password")) {
          ({ hasError, error } = validatePassword(
            elem,
            formData[elem].value,
            formData
          ));
        } else {
          ({ hasError, error } = validateCustomInput(
            elem,
            formData[elem].value
          ));
        }

        if (hasError === true) {
          isFormValid = false;
        }

        dispatch({
          type: UPDATE_FORM,
          data: {
            ...formData[elem],
            name: elem,
            hasError,
            error,
            touched: true,
            isFormValid,
          },
        });
      } else {
        if (isUpdateForm) {
          if (elem.includes("password") || elem.includes("Password")) {
            if (formData[elem].value.trim() !== "") {
              ({ hasError, error } = validatePassword(
                elem,
                formData[elem].value,
                formData
              ));
              if (hasError === true) {
                isFormValid = false;
              }
            }
          }
        }

        if (elem.includes("phoneNumber")) {
          ({ hasError, error } = validatePhoneNumber(
            elem,
            formData[elem].value,
            formData[elem].isRequired,
            dispatch
          ));
          if (hasError === true) {
            isFormValid = false;
          }
        }
      }
    }
  });

  // Ensure confirmPassword validation is always checked
  if (formData.confirmPassword) {
    const { hasError, error } = validatePassword(
      "confirmPassword",
      formData.confirmPassword.value,
      formData
    );
    if (hasError === true) {
      isFormValid = false;
      dispatch({
        type: UPDATE_FORM,
        data: {
          ...formData.confirmPassword,
          name: "confirmPassword",
          hasError,
          error,
          touched: true,
          isFormValid,
        },
      });
    }
  }

  return isFormValid;
};

export const onInputChange = (name, value, dispatch, formState, validate) => {
  const { hasError, error } = validateInput(name, value, validate, formState);
  let isFormValid = true;

  for (const key in formState) {
    const item = formState[key];
    // Check if the current field has error
    if (key === name && hasError) {
      isFormValid = false;
      break;
    } else if (key !== name && item.hasError) {
      // Check if any other field has error
      isFormValid = false;
      break;
    }
  }

  dispatch(
    updateFormAction({
      name,
      value,
      hasError,
      error,
      touched: false,
      isFormValid,
    })
  );
};

export const onFocusOut = (name, value, dispatch, formState, validate) => {
  const { hasError, error } = validateInput(name, value, validate, formState);
  let isFormValid = true;
  for (const key in formState) {
    const item = formState[key];
    if (key === name && hasError) {
      isFormValid = false;
      break;
    } else if (key !== name && item.hasError) {
      isFormValid = false;
      break;
    }
  }

  dispatch(
    updateFormAction({
      name,
      value,
      hasError,
      error,
      touched: true,
      isFormValid,
    })
  );
};

export const validateForm = (formData, dispatch) => {
  let formDataArray = Object.keys(formData);
  let isFormValid = true;

  formDataArray.forEach((elem) => {
    if (elem !== "isFormValid") {
      const { hasError, error } = validateInput(elem, formData[elem].value);
      if (hasError === true) {
        isFormValid = false;
      }
      dispatch({
        type: UPDATE_FORM,
        data: {
          ...formData[elem],
          name: elem,
          hasError,
          error,
          touched: true,
          isFormValid,
        },
      });
    }
  });

  return isFormValid;
};

export const formsReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_FORM:
      const { name, value, hasError, error, touched, isFormValid } =
        action.data;
      return {
        ...state,
        // update the state of the particular field,
        // by retaining the state of other fields
        [name]: { ...state[name], value, hasError, error, touched },
        isFormValid,
      };
    case FILL_FORM:
      return {
        ...state,
        ...action.payload,
      };
    case TO_NULL:
      return null;
    default:
      return state;
  }
};

export const validateContactForm = (formData, setFormData) => {
  const emailReg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let isValid = true;

  if (formData.contactEmail.value && !emailReg.test(String(formData.contactEmail.value).toLowerCase())) {
    isValid = false;
    setFormData({
      type: "SET_ERROR",
      field: "contactEmail",
      error: "That's not a valid email. Try a different email address",
    });
    console.log("Invalid Contact Email");
  }

  if (formData.contactPhone.value && (!isPossiblePhoneNumber(formData.contactPhone.value) || !isValidPhoneNumber(formData.contactPhone.value))) {
    isValid = false;
    setFormData({
      type: "SET_ERROR",
      field: "contactPhone",
      error: "Incorrect phone number",
    });
    console.log("Invalid Contact Phone");
  }

  console.log("isValid", isValid);
  return isValid;
};
